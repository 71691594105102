<template>
  <header class="inner-header ">
    <div class="container">
      <div class="row header-row">
        <div class="header-col">
          <div class="logo-holder">
            <div class="logo-wrap">
              <logo route-name="Home" />
            </div>
            <router-link
              :to="{name: 'Home'}"
              class="back-home"
              v-text="'عودة للرئيسية'"
            />
          </div>
        </div>
        <div class="header-col">
          <div class="user-btn-holder">
            <router-link
              tag="a"
              :to="{name: isAuthenticated ? 'UserSpace' : 'Login'}"
              class="login-btn btn"
            >
              <img
                src="../images/users.svg"
                alt="logo"
                width="18"
                height="18"
              >
              <span v-if="isAuthenticated">{{ $t('landing.go_to_user_space') }}</span>
              <span v-else>{{ $t('register.signIn') }}</span>
            </router-link>
          </div>
          <focus-hidden
            v-if="accessibilityFocus"
            :destroy="false"
          />
          <button
            type="button"
            class="menu-btn"
            @click="toggleMenu"
          >
            <svg
              width="21"
              height="17"
              viewBox="0 0 32 32"
            >
              <icon-menu />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <landing-menu
      :is-menu-open="isMenuOpen"
      @closeMenu="closeMenu"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import LandingMenu from '@/views/landing/landing-menu/LandingMenu.vue';

export default {
  name: 'LandingHeader',
  components: {
    LandingMenu,
  },
  data() {
    return {
      isFixed: false,
      isMobOpened: false,
      isDropOpened: false,
      isMenuOpen: false,
      accessibilityFocus: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      isAuthenticated: 'user/isAuthenticated',
    }),
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
        this.accessibilityFocus = true;
        this.$nextTick(() => { this.accessibilityFocus = false; });
      }
    },
    setLang(lang) {
      const { locale } = this.$route.params;
      if (locale && locale !== lang) {
        this.$router.push({
          path: this.$route.fullPath.replace(`/${locale}`, `/${lang}`),
        });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
