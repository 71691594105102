<template>
  <div
    class="menu-wrap"
    :class="{open: isMenuOpen}"
  >
    <button
      type="button"
      class="close-menu"
      @click="closeMenu"
    >
      <img
        src="../images/close.svg"
        alt="close-icon"
        width="38"
        height="38"
      >
    </button>
    <div
      class="menu-nav"
    >
      <nav-list
        class="menu-nav-list"
        item-class-name="menu-nav-list-item"
        @itemClick="closeMenu"
      />
    </div>
  </div>
</template>

<script>
import NavList from '@/views/landing/nav-list/NavList.vue';

export default {
  name: 'LandingMenu',
  components: { NavList },
  props: {
    isMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu', true);
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
