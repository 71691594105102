export const GET_ENTITIES_BY_MEMBER = `query GetEntitiesByMember($page: Int!, $size: Int!, $status: String, $memberRoleType: String) {
  entities(page: $page, size: $size, status: $status, memberRoleType: $memberRoleType) {
    entities {
      id
      unifiedNumber700
      isNama
      activityIds
      goalIds
      foundFor
      currentPosition {
        positionUid
      }
      entityProfile {
        logo {
          origin {
            url: path
            uid
          }
          x100 {
            url: path
            uid
          }
          x200 {
            url: path
            uid
          }
          x300 {
            url: path
            uid
          }
          x500 {
            url: path
            uid
          }
        }
      }
      entityUid
      entityLicenseNumber
      classificationDetails {
        departmentArTitle
        departmentEnTitle
        firstSubClassificationArTitle
        firstSubClassificationEnTitle
        mainClassificationArTitle
        mainClassificationEnTitle
        secondSubClassificationArTitle
        secondSubClassificationEnTitle
        secondSubClassificationArDescription
        departmentId
      }
      createdAt
      updatedAt
      acceptedAt
      nameArabic
      registrationDateHijri
      nameEnglish
      type
      secondSubClassificationId
      owner {
        id
        firstName
        lastName
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const GET_ENTITIES_BY_OWNER = `query GetEntitiesByOwner($page: Int!, $size: Int!, $status: String) {
  entities(page: $page, size: $size, status: $status) {
    entities {
      id
      isNama
      unifiedNumber700
      activityIds
      goalIds
      entityUid
      foundFor
      entityLicenseNumber
      currentPosition {
        positionUid
      }
      entityProfile {
        logo {
          origin {
            url: path
            uid
          }
          x100 {
            url: path
            uid
          }
          x200 {
            url: path
            uid
          }
          x300 {
            url: path
            uid
          }
          x500 {
            url: path
            uid
          }
        }
      }
      classificationDetails {
        departmentArTitle
        departmentEnTitle
        firstSubClassificationArTitle
        firstSubClassificationEnTitle
        mainClassificationArTitle
        mainClassificationEnTitle
        secondSubClassificationArTitle
        secondSubClassificationEnTitle
        secondSubClassificationArDescription
        departmentId
      }
      createdAt
      updatedAt
      acceptedAt
      nameArabic
      nameEnglish
      registrationDateHijri
      type
      secondSubClassificationId
      owner {
        id
        firstName
        lastName
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const GET_ENTITIES = `query entities($page: Int!, $size: Int!, $withAcceptedNamaEntities: Boolean) {
  entities(page: $page, size: $size, withAcceptedNamaEntities: $withAcceptedNamaEntities) {
    entities {
      entityMultiStatus {
        membersReplacementType
        secondApprovalType
        supervisoryChanges
      }
      remainingData {
        expireAt
        remainingType
        remainingValue
        startDate
      }
      actionExpectation
      id
      isNama
      activityIds
      goalIds
      createdAt
      updatedAt
      acceptedAt
      nameArabic
      registrationDateHijri
      entityUid
      entityLicenseNumber
      nameEnglish
      status
      type
      owner {
        id
        firstName
        lastName
      }
    }
    meta {
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const FIND_ENTITY_BY_ID = `query findEntityById($id: Int!) {
  findEntityById(id: $id) {
    families{
      name
      relation
    }
    unifiedNumber700
    cityUid
    copiedFrom
    autocreateMembershipForOwner
    foundFor
    createdAt
    establishmentApprovalProofs {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    entityProfile {
      logo {
        origin {
          url: path
          uid
        }
        x100 {
          url: path
          uid
        }
        x200 {
          url: path
          uid
        }
        x300 {
          url: path
          uid
        }
        x500 {
          url: path
          uid
        }
      }
    }
    areasOfActivity {
      cities {
        arTitle
        enTitle
        regionCode
        uid
      }
      cityUids
      entityId
      region {
        arTitle
        cities {
          arTitle
          enTitle
          regionCode
          uid
        }
        code
        enTitle
      }
      regionCode
      type
    }
    membershipRequirements
    entityUid
    entityLicenseNumber
    id
    isNama
    typeOfBenefits
    membershipType
    nameArabic
    ceoCustomCondition
    ceoEducationalLevelCondition
    ceoPreviousExperienceCondition
    entityLicenses {
      uid
    }
    bankCertificates {
      uid
    }
    eligibilityStudyFiles {
      uid
    }
    entityPolicies {
      uid
    }
    establishmentDecisions {
      uid
    }
    migrationDelegateLetters {
      uid
    }
    supportingDocuments {
      uid
    }
    classificationDetails {
      departmentArTitle
      departmentEnTitle
      firstSubClassificationArTitle
      firstSubClassificationEnTitle
      mainClassificationArTitle
      mainClassificationEnTitle
      secondSubClassificationArTitle
      secondSubClassificationEnTitle
      secondSubClassificationArDescription
    }
    eligibilityStudyFiles {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    bankCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    type
    numberOfShares
    minNumOfSharesPerMember
    shareValue
    nameEnglish
    boardFirstTermLength
    boardLaterTermsLength
    supportingDocuments {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    regionCode
    registrationDateHijri
    secondSubClassificationId
    status
    goalIds
    activityIds
    goals
    updatedAt
    acceptedAt
    city {
      arTitle
      enTitle
      regionCode
      uid
    }
    region {
      arTitle
      code
      enTitle
    }
    progressStep {
      step
    }
    entityNationalAddress {
      additionalNumber
      buildingNumber
      postCode
      streetName
    }
    owner {
      id
      educationalCertificates {
        id: uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
        attachedAt
      }
      firstName
      fatherName
      grandfatherName
      lastName
    }
  }
  findOwnerAsAMemberUserRequestByEntityId(entityId: $id) {
    createdAt
    customPosition
    financeContributionAmount
    governmental
    email
    familyRelation {
      uid
      arTitle
      enTitle
    }
    delegatorProof {
      entityName
      id
      organizationName
      organizationNumber
      userRequestId
      organizationName
      organizationNumber
      delegatorProofLetters {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      nonGovernmentalProof {
        associationMinutesProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        commercialRegistrationNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        nonProfitOrganizationLicenseProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        endowmentDeedNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        zakatCertificateNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        facilityRecordNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        gosiNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
        }
        createdAt
        delegatorProofId
        facilityRecordNumber
        gosiNumber
        id
        proofType
        proofValue
        updatedAt
        zakatCertificateNumber
      }
    }
    memberId
    memberType
    disclosureForm {
      acrm
      crmi
      crmo
      fffl
      lknw
      naff
      nmbd
      oman
      resi
      rman
      id
      userRequestId
    }
    numberOfShares
    position {
      arTitle
      enTitle
      uid
    }
    positionUid
    member {
      nationalId
    }
    role
    specialized
    unitId
    unitType
    id
  }
}`;

export const TOTAL_CONTRIBUTION = `query TotalContributions($entityId: Int!) {
  totalContributions(entityId: $entityId) {
    entityId
    totalContributions
  }
}`;

const CREATE_ENTITY = `cityUid
    autocreateMembershipForOwner
    foundFor
    createdAt
    entityUid
    entityLicenseNumber
    id
    nameArabic
    nameEnglish
    regionCode
    registrationDateHijri
    secondSubClassificationId
    status
    goalIds
    updatedAt
    city {
      arTitle
      enTitle
      regionCode
      uid
    }
    region {
      arTitle
      code
      enTitle
    }
    progressStep {
      step
    }
    entityNationalAddress {
      additionalNumber
      buildingNumber
      postCode
      streetName
    }
    eligibilityStudyFiles {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    bankCertificates {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    supportingDocuments {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }
    owner {
      id
      educationalCertificates {
        id: uid
        name: filename
        size: fileSize
        url: path
        type: mimeType
      }
    }
    establishmentApprovalProofs {
      attachedAt
      createdAt
      docType
      fileSize
      filename
      mimeType
      path
      uid
      updatedAt
    }`;

export const CREATE_ENTITY_PA = `mutation createPrivateAssociationEntity($privateAssociationEntityCreateAttributes: CreatePrivateAssociationEntityInput!) {
  createPrivateAssociationEntity(privateAssociationEntityCreateAttributes: $privateAssociationEntityCreateAttributes) {
    ${CREATE_ENTITY}
  }
}`;

export const CREATE_ENTITY_PC = `mutation createPrivateCorporationEntity($privateCorporationEntityCreateAttributes: CreatePrivateCorporationEntityInput!) {
  createPrivateCorporationEntity(privateCorporationEntityCreateAttributes: $privateCorporationEntityCreateAttributes) {
    ${CREATE_ENTITY}
  }
}`;

export const CREATE_ENTITY_CA = `mutation createCooperativeAssociationEntity($cooperativeAssociationEntityCreateAttributes: CreateCooperativeAssociationEntityInput!) {
  createCooperativeAssociationEntity(cooperativeAssociationEntityCreateAttributes: $cooperativeAssociationEntityCreateAttributes) {
    ${CREATE_ENTITY}
  }
}`;

export const UPDATE_ENTITY_PA = `mutation updatePrivateAssociationEntity(
  $privateAssociationEntityUpdateAttributes: UpdatePrivateAssociationEntityInput!,
  $progressStepUpdateAttributes: ProgressStepUpdateInput!
) {
  updatePrivateAssociationEntity(privateAssociationEntityUpdateAttributes: $privateAssociationEntityUpdateAttributes) {
    ${CREATE_ENTITY}
  }
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
    unitType
    step
  }
}`;

export const UPDATE_ENTITY_PC = `mutation updatePrivateCorporationEntity(
  $privateCorporationEntityUpdateAttributes: UpdatePrivateCorporationEntityInput!,
  $progressStepUpdateAttributes: ProgressStepUpdateInput!
) {
  updatePrivateCorporationEntity(privateCorporationEntityUpdateAttributes: $privateCorporationEntityUpdateAttributes) {
    ${CREATE_ENTITY}
  }
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
    unitType
    step
  }
}`;

export const UPDATE_ENTITY_CA = `mutation updateCooperativeAssociationEntity(
  $cooperativeAssociationEntityUpdateAttributes: UpdateCooperativeAssociationEntityInput!,
  $progressStepUpdateAttributes: ProgressStepUpdateInput!
) {
  updateCooperativeAssociationEntity(cooperativeAssociationEntityUpdateAttributes: $cooperativeAssociationEntityUpdateAttributes) {
    ${CREATE_ENTITY}
  }
  updateProgressStep(progressStepUpdateAttributes: $progressStepUpdateAttributes) {
    unitId
    unitType
    step
  }
}`;

export const FETCH_FILTERED_ENTITIES = `query FetchFilteredEntities(
  $page: Int!,
  $size: Int!,
  $name: String,
  $mainClassificationId: Int,
  $firstSubClassificationId: Int,
  $secondSubClassificationId: Int,
  $hijriDateFrom: String,
  $hijriDateTo: String,
  $goalId: Int,
  $activityId: Int,
  $type: EntityType,
  $excludedType: [EntityType!],
  $regionCode: String
  $cityUid: [String!]
  $entityUid: String
  $unifiedNumber700: String
){
  publicListEntities(
    page: $page
    size: $size
    name: $name
    mainClassificationId: $mainClassificationId
    firstSubClassificationId: $firstSubClassificationId
    secondSubClassificationId: $secondSubClassificationId
    hijriDateFrom: $hijriDateFrom
    hijriDateTo: $hijriDateTo
    goalId: $goalId
    activityId: $activityId
    type: $type
    excludedType: $excludedType
    regionCode: $regionCode
    cityUid: $cityUid
    entityUid: $entityUid
    unifiedNumber700: $unifiedNumber700
  ) {
    entities {
      entityLicenseNumber
      unifiedNumber700
      entityProfile {
        logo {
          origin {
            url: path
            uid
          }
          x100 {
            url: path
            uid
          }
          x200 {
            url: path
            uid
          }
          x300 {
            url: path
            uid
          }
          x500 {
            url: path
            uid
          }
        }
      }
      activities{
        arTitle
      }
      owner {
        id
      }
      createdAt
      acceptedAt
      foundFor
      classificationDetails{
        mainClassificationArTitle
        firstSubClassificationArTitle
        secondSubClassificationArTitle
        departmentArTitle
      }

      goals {
        ... on CommonGoal {
          arTitle
          id
        }
        ... on FamilyTrustGoal {
          arTitle
          enTitle
          id
        }
      }
      id
      nameArabic
      nameEnglish
      numberOfBoardMembers
      secondSubClassificationId
      type
      updatedAt
      updatedAtHijri: registrationDateHijri
    }
    meta{
      page
      pageCount
      recordsCount
      size
    }
  }
}`;

export const FINAL_SUBMIT_ENTITY_PA = `mutation submitPrivateAssociationEntity(
  $privateAssociationEntitySubmitAttributes: SubmitEntityInput!,
) {
  submitPrivateAssociationEntity(privateAssociationEntitySubmitAttributes: $privateAssociationEntitySubmitAttributes) {
    id
  }
}`;

export const FINAL_SUBMIT_ENTITY_PC = `mutation submitPrivateCorporationEntity(
  $privateCorporationEntitySubmitAttributes: SubmitEntityInput!,
) {
  submitPrivateCorporationEntity(privateCorporationEntitySubmitAttributes: $privateCorporationEntitySubmitAttributes) {
    id
  }
}`;

export const FINAL_SUBMIT_ENTITY_CA = `mutation submitCooperativeAssociationEntity(
  $cooperativeAssociationEntitySubmitAttributes: SubmitEntityInput!,
) {
  submitCooperativeAssociationEntity(cooperativeAssociationEntitySubmitAttributes: $cooperativeAssociationEntitySubmitAttributes) {
    id
  }
}`;

export const FETCH_REQUEST_TRACKER = `query fetchRequestTracker(
  $requestId: Int!,
  $requestType: RequestTrackersRequestType!,
) {
  findRequestTracker(requestId: $requestId, requestType: $requestType) {
    rows {
      cells {
        type
        enTitle
        arTitle
        state
        lineDirections
        rejectObject {
          accountId
          message
        }
      }
    }
  }
}`;

export const LIST_CONTRIBUTION_SOURCES = `query ListContributionSources($entityId: Int, $page: Int, $size: Int) {
  listContributionSources(entityId: $entityId, page: $page, size: $size) {
    contributionsSources {
      amount
      contributionProofs {
        attachedAt
        createdAt
        docType
        fileSize
        filename
        mimeType
        path
        uid
        updatedAt
      }
      createdAt
      entityId
      id
      kind
      title
      updatedAt
    }
  }
}`;

export const FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID = `query FindTempBankAccountByEntityId($entityId: Int!) {
  findTempBankAccountByEntityId(entityId: $entityId) {
    iban
    id
    status
  }
}`;

export const VALIDATE_MEMBERS = `mutation ValidateMembers($unitId: Int!, $unitType: UnitType!) {
  validatePotentialMembersByUnit(unitId: $unitId, unitType: $unitType) {
    unitId
    unitType
  }
}`;

export const DELETE_ENTITY_REQUEST = `mutation deleteEntityRequest($id: Int!) {
  deleteEntity(id: $id) {
    id
  }
}`;

export const CREATE_OR_UPDATE_CONTRIBUTION_SOURCE = `mutation createOrUpdateContributionSource($contributionSourcesCreateOrUpdateAttributes: ContributionSourcesCreateOrUpdateInput!) {
  createOrUpdateContributionSource(contributionSourcesCreateOrUpdateAttributes: $contributionSourcesCreateOrUpdateAttributes) {
    amount
    createdAt
    entityId
    id
    kind
    title
    updatedAt
  }
}`;

export const DELETE_CONTRIBUTION_SOURCE = `mutation deleteContributionSource($id: Int!) {
  deleteContributionSource(id: $id) {
    id
  }
}`;
