<template>
  <footer class="landing-footer">
    <div class="footer-top">
      <div class="container">
        <div class="landing-footer-nav">
          <nav-list
            class="footer-nav-list"
            item-class-name="list-item"
          />
        </div>
      </div>
    </div>
    <div class="entry-footer">
      <div class="container-landing">
        <div class="landing-footer-logos">
          <div class="footer-row">
            <div class="footer-col main-footer-logo">
              <div class="footer-logo-label-wrap">
                <span class="footer-logo-label">نسخة تجريبية</span>
                <router-link
                  :to="{name: 'Home'}"
                  class="home-header-logo-img"
                  tabindex="0"
                >
                  <img
                    width="118"
                    height="44"
                    src="../images/logo.svg"
                    alt="Nawa"
                  >
                </router-link>
              </div>
              <div class="footer-copyright">
                <p>جميع الحقوق محفوظة © 2022</p>
              </div>
              <div class="footer-other-logos">
                <a
                  href="https://ncnp.gov.sa/ar"
                  rel="noopener"
                  target="_blank"
                >
                  <img
                    width="150"
                    height="65"
                    src="../images/footer_second_logo.svg"
                    alt="footer-logo"
                  >
                </a>
                <a>
                  <img
                    width="75"
                    height="51"
                    src="../images/vision2030.svg"
                    alt="footer-logo"
                  >
                </a>
              </div>
            </div>
            <div class="footer-col">
              <ul class="footer-social-list">
                <li class="list-item">
                  <a
                    class="footer-social-link"
                    href="https://twitter.com/NAWA_gov"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 32 32"
                    ><icon-twitter /></svg>
                  </a>
                </li>
                <li class="list-item">
                  <a
                    class="footer-social-link"
                    href="https://www.linkedin.com/in/nawa-sa-3007a5212/"
                    rel="noopener"
                    target="_blank"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 32 32"
                    >
                      <icon-linkedin />
                    </svg>
                  </a>
                </li>
                <li class="list-item">
                  <a
                    class="footer-social-link"
                    rel="noopener"
                    href="https://www.youtube.com/channel/UCjc9NdesBjwmrQ3KpcMMBew/featured"
                    target="_blank"
                  ><svg
                    width="21"
                    height="15"
                    viewBox="0 0 32 32"
                  >
                    <icon-youtube />
                  </svg></a>
                </li>
                <li class="list-item">
                  <a
                    class="footer-social-link"
                    rel="noopener"
                    href="https://www.instagram.com/nawa_gov/?r=nametag"
                    target="_blank"
                  ><svg
                    width="21"
                    height="16"
                    viewBox="0 0 32 32"
                  >
                    <icon-instagram />
                  </svg></a>
                </li>
                <li class="list-item">
                  <a
                    class="footer-social-link"
                    rel="noopener"
                    href="https://www.snapchat.com/add/nawa_gov?share_id=MkI1NkQ2&locale=en_US"
                    target="_blank"
                  ><svg
                    width="21"
                    height="16"
                    viewBox="0 0 32 32"
                  >
                    <icon-snap-chat />
                  </svg></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="landing-footer-quick-links">
          <router-link
            tag="a"
            :to="{name: 'PrivacyPolicy'}"
          >
            {{ $t('header.privacyPolicy') }}
          </router-link>
          <!-- <a
            target="_blank"
            rel="noopener"
            href="https://www.questionpro.com/t/ATPjIZnkWk"
          >
            {{ $t('home.info.survey') }}
          </a> -->
        </div>
        <scrollactive
          :modify-url="false"
          :duration="800"
        >
          <a
            id="top-control"
            href="#app"
            class="scrollactive-item"
          >
            <img
              src="../images/arrow-up.svg"
              alt="arrow-icon"
              width="25"
              height="24"
            >
          </a>
        </scrollactive>
      </div>
    </div>
  </footer>
</template>

<script>
import NavList from '@/views/landing/nav-list/NavList.vue';
import IconLinkedin from '@takamol/nawa-library/src/components/icons/IconLinkedin.vue';
import IconTwitter from '@takamol/nawa-library/src/components/icons/IconTwitter.vue';
import IconYoutube from '@takamol/nawa-library/src/components/icons/IconYoutube.vue';
import IconInstagram from '@takamol/nawa-library/src/components/icons/IconInstagram.vue';
import IconSnapChat from '@takamol/nawa-library/src/components/icons/IconSnapChat.vue';

export default {
  name: 'LandingFooter',
  components: {
    NavList,
    IconLinkedin,
    IconYoutube,
    IconTwitter,
    IconInstagram,
    IconSnapChat,
  },
  methods: {
    setLang(lang) {
      const { locale } = this.$route.params;
      if (locale && locale !== lang) {
        this.$router.push({
          path: this.$route.fullPath.replace(`/${locale}`, `/${lang}`),
        });
      }
    },
  },
};
</script>

<style src="./styles.scss" lang="scss"/>
